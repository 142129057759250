import {
  get, post, put
} from "./http";

/**
 * @desc 图片上传
 * @type POST
 * @params files
 */
export const uploadImage = params => {
  return post('/upload', params)
}

/**
 * @desc 商户首页
 * @type GET
 * @params sid=864, encrypt=d8d8c02194312a38eec68962087bdf6f
 */
export const getShHomeApi = params => {
  return get('/sh/home', params)
}

/**
 * @desc 维修类型
 * @type GET
 * @params
 */
export const getRepairTypesApi = () => {
  return get('/worker/types')
}

/**
 * @desc 维修单创建
 * @type POST
 * @params sid, encrypt, area, type, depart_id, description, images, is_old, contact, mobile
 */
export const postShCreateOrderApi = params => {
  return post('/sh/workerOrder', params)
}

/**
 * @desc 维修单列表(商户端)
 * @type GET
 * @params sid, encrypt, page, type
 */
export const getShOrderListApi = params => {
  return get('/sh/workerOrder', params)
}

/**
 * @desc 维修单详情(商户端)
 * @type GET
 * @params sid encrypt order_id
 */
export const getShOrdedrDetailApi = params => {
  return get('/sh/orderContent', params)
}

/**
 * @desc 确认维修员到场
 * @type POST
 * @params sid, encrypt, order_id
 */
export const postShArriveConfirmApi = params => {
  return post('/sh/arriveConfirm', params)
}

/**
 * @desc 确认维修方案
 * @type POST
 * @params sid, encrypt, order_id
 */
export const postShConfirmPlanApi = params => {
  return post('/sh/planConfirm', params)
}

/**
 * @desc 商户确认维修结果
 * @type POST
 * @params sid, encrypt, order_id
 */
export const postShConfirmResultApi = params => {
  return post('/sh/finishConfirm', params)
}

/**
 * @desc 商户取消维修单
 * @type POST
 * @params sid, encrypt, order_id remark
 */
export const postShCancelOrderApi = params => {
  return post('/sh/orderCancel', params)
}

/**
 * @desc 维修员登录
 * @type POST
 * @params username password
 */
export const postLoginApi = params => {
  return post('/user/login', params)
}

/**
 * @desc 维修员排班(读取)
 * @type GET
 * @params access_token date(默认当天)
 */
export const getComposApi = params => {
  return get('/worker/schedul', params)
}

/**
 * @desc 维修员排班(提交)
 * @type POST
 * @params access_token, staff_ids date(默认当天)
 */
export const postComposApi = params => {
  return post('/worker/schedul', params)
}

/**
 * @desc 维修列表(维修员端)
 * @type GET
 * @params access_token page type
 */
export const getRepairListApi = params => {
  return get('/worker/order', params)
}

/**
 * @desc 派单
 * @type POST
 * @params access_token distribution_uid order_id
 */
export const postSendOrderApi = params => {
  return post('/order/distribution', params)
}

/**
 * @desc 出勤人员表
 * @type GET
 * @params access_token
 */
export const getWorkerListApi = params => {
  return get('/worker/attendancePeople', params)
}

/**
 * @desc 抢单
 * @type POST
 * @params access_token order_id
 */
export const postRaceOrderApi = params => {
  return post('/order/receiv', params)
}

/**
 * @desc 维修详情(维修员端)
 * @type GET
 * @params access_token order_id
 */
export const getRepairDetailApi = params => {
  return get('/order/content', params)
}

/**
 * @desc 维修员到场
 * @type POST
 * @params access_token order_id
 */
export const postArriveApi = params => {
  return post('/worker/arrive', params)
}

/**
 * @desc 维修方案提交
 * @type POST
 * @params access_token order_id plan
 */
export const postRepairPlanApi = params => {
  return post('/order/plan', params)
}

/**
 * @desc 提交维修结果
 * @type POST
 * @params access_token order_id images
 */
export const postSubmitResultApi = params => {
  return post('/order/finish', params)
}

/**
 * @desc 工程退单
 * @type POST
 * @params access_token order_id remark
 */
export const postCancelOrderApi = params => {
  return post('/order/refund', params)
}

/**
 * @desc 转单
 * @type POST
 * @params access_token turn_uid order_id
 */
export const postTurnOrderApi = params => {
  return post('/order/turn', params)
}

// 公区报修
/**
 * @desc 维修单创建
 * @type POST
 * @params access_token type depart_id description images is_old contact mobile
 */
export const postRepairApi = params => {
  return post('/workerOrder/create', params)
}

/**
 * @desc 结果确认
 * @type POST
 * @params access_token order_id
 */
export const postSureResultApi = params => {
  return post('/workerOrder/confirm', params)
}

// 证照办理
/**
 * @desc 商户信息
 * @type GET
 * @params sid encrypt
 */
export const getMerchantInfoApi = params => {
  return get('/sh/info', params)
}

/**
 * @desc 施工证申请
 * @type POST
 * @params sid encrypt area start end content[] other_area project_builder in_out company head scene fire
 */
export const postBuildApi = params => {
  return post('/cert/construction', params)
}

/**
 * @desc 吊车申请
 * @type POST
 * @params sid encrypt area start end content[] other_area project_builder in_out company head scene fire
 */
export const postCraneApi = params => {
  return post('/cert/crane', params)
}

/**
 * @desc 进货申请单
 * @type POST
 * @params sid encrypt name mobile car_type car_no date time goods_type goods
 */
export const postInGoodsApi = params => {
  return post('/cert/ingoods', params)
}

/**
 * @desc 出货申请单
 * @type POST
 * @params sid encrypt name mobile car_type car_no date time goods_type goods
 */
export const postOutGoodsApi = params => {
  return post('/cert/outgoods', params)
}

/**
 * @desc 动火证申请
 * @type POST
 * @params sid encrypt name mobile date time fire_type fire_ext_num company content copywrit
 */
export const postFireApi = params => {
  return post('/cert/dofire', params)
}

/**
 * @desc 我的证件
 * @type GET
 * @params sid encrypt type
 */
export const getLicenseListApi = params => {
  return get('/cert/list', params)
}

/**
 * @desc 证件详情
 * @type GET
 * @params sid encrypt id
 */
export const getLicenseDetailApi = params => {
  return get('/cert/content', params)
}
/**
 * @desc 证件详情(内部)
 * @type GET
 * @params access_token id
 */
export const getLicenseNDetailApi = params => {
  return get('/cert/confirmContent', params)
}

/**
 * @desc 证照审核
 * @type POST
 * @params access_token id type=1
 */
export const postCertConfirmApi = params => {
  return post('/cert/confirm', params)
}

/**
 * @desc 保安核销
 * @type POST
 * @params access_token order_no
 */
export const postCertVerifyApi = params => {
  return post('/cert/verify', params)
}

/**
 * @desc 建垃凭证提交
 * @type POST
 * @params access_token sid num
 */
export const postCertTrashVerifyApi = params => {
  return post('cert/trashverify', params)
}

/**
 * @desc 维修端-证照列表
 * @type GET
 * @params access_token type 1未审核 2已通过 3已失效 4已驳回
 */
export const getRepairLicenseListApi = params => {
  return get('cert/alllist', params)
}

/**
 * @desc 维修端-修改密码
 * @type PUT
 * @params access_token oldpassword newpassword confirm_password
 */
export const putChangePwdAPI = params => {
  return put('user/resetpwd', params)
}

/**
 * @desc 商户端-修改密码
 * @type POST
 * @params sid encrypt old_passwd passwd
 */
export const putShChangePwdAPI = params => {
  return post('sh/passwdReset', params)
}

/**
 * @desc 逾期列表
 * @type GET
 * @desc type date
 */
export const getOverdueListApi = params => {
  return get('/overdue', params)
}
